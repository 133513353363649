<!-- @Author: Yu_Bo -->
<template>
	<div class='details_model zc_dialog_box'>
		<el-dialog :visible.sync="dialogVisible" width="635px" :close-on-click-modal='false'>
		  <img class="dialog_bg" src="@/assets/images/background/details_background.png" alt="">
      <div class="title" slot="title">
		    <div class="name">模型详情</div>
		  </div>
      <div class="details_main">
        <div class="main_left">
          <el-image style="width: 100%; height: 100%" :src="thumbnail" fit="scale-down"></el-image>
        </div>
        <div class="main_right">
          <div class="right_text">
            <div class="t_name">名称：</div>
            <div class="t_txt">{{name}}
              <img v-if="gender==1" src="@/assets/images/sex/nan.png" alt="">
              <img v-if="gender==2" src="@/assets/images/sex/nv.png" alt="">
            </div>
          </div>
          <div class="right_text">
            <div class="t_name">星座：</div>
            <div class="t_txt">{{constellation || '--'}}
              <!-- 摩羯座 ♑️ <img src="@/assets/images/sex/sz_icon.png" alt=""> -->
            </div>
          </div>
          <div class="right_text">
            <div class="t_name">年龄：</div>
            <div class="t_txt">{{age}}岁</div>
          </div>
          <div class="right_text" v-if="labelList.length">
            <div class="t_name">标签：</div>
            <div class="t_tip">
              <span v-for="(item,index) in labelList" :key="index">{{item}}</span>
            </div>
          </div>
          <div class="right_text">
            <div class="t_name">简介：</div>
            <div class="t_txt">{{describe || '--'}}</div>
          </div>
          <div class="right_text">
            <div class="t_name" style="width: 140px;">有效期截止日期：</div>
            <div class="t_txt" v-if="type==1 || type==3">
              {{expiry_num?expiry_num+'到期':'账号使用内有效'}}
            </div>
            <div class="t_txt" v-if="type==2 || type==4">
              {{expiry_num}}天内有效
            </div>
          </div>
        </div>
      </div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        type:1,
        thumbnail:'',
        name:'',
        gender:1,//1男，2女
        constellation:'',
        age:0,
        labelList:[],
        describe:'',
        expiry_num:"",
				dialogVisible:false
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开
      openDialog(info,val){
        this.thumbnail=info.thumbnail
        this.name=info.name
        this.gender=info.gender
        this.age=info.age
        if(info.label){
          this.labelList=info.label.split(',')
        }
        this.describe=info.describe
        this.constellation=info.constellation
        this.type=val
        this.expiry_num = this.type==1?info.expiry_date:info.expire_day
        this.dialogVisible=true
      },
    },
	}
</script>

<style lang='scss' scoped>
	.details_model{
    ::v-deep .el-dialog {
      height: 510px;
      background: none
    }
    .dialog_bg{
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 100%;
      height: 510px;
    }
    .details_main{
      box-sizing: border-box;
      width: 100%;
      height: 415px;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      .main_left{
        width: 265px;
        height: 100%;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .main_right{
        box-sizing: border-box;
        width: 300px;
        height: 100%;
        .right_text{
          padding-top: 35px;
          display: flex;
          align-items: center;
          .t_name{
            width: 70px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .t_txt{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;
            img{
              display: block;
              width: 16px;
              height: 16px;
              margin-top: 2px;
              margin-left: 6px;
            }
          }
          .t_tip{
            display: flex;
            align-items: center;
            span{
              display: block;
              padding: 0 15px;
              height: 26px;
              line-height: 26px;
              background: rgba(46,75,242,0.1);
              border-radius: 13px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
              margin-left: 10px;
            }
            span:first-child{
              margin-left: 0;
            }
          }
        }
        .right_text:first-child{
          padding-top: 20px;
        }
      }
    }
	}
</style>
